

































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";

export default Vue.extend({
  name: "drawing-board",
  props: {
    value: Blob,
    title: {
      default: () => "Signature",
    },
    color: {
      type: String,
      default: () => "#000000",
    },
    strokeWidth: {
      type: Number,
      default: () => 2,
    },
    attrs: {
      type: Object,
      default: () => ({
        width: "500px",
        height: "270px",
      }),
    },
  },
  data: function () {
    return {
      mouse: {
        current: {
          x: 0,
          y: 0,
        },
        previous: {
          x: 0,
          y: 0,
        },
        down: false,
      },
      canvas: null,
    };
  },
  computed: {
    currentMouse: function () {
      const canvas = this.$refs.canvas;
      var rect = (canvas as any).getBoundingClientRect();

      return {
        x: (this as any).mouse.current.x - rect.left,
        y: (this as any).mouse.current.y - rect.top,
      };
    },
  },
  mounted() {
    (this as any).setupCanvas();
    (this as any).clearCanvas();
  },
  methods: {
    draw() {
      if ((this as any).mouse.down) {
        var ctx = (this as any).canvas;

        ctx.clearRect(0, 0, 800, 800);
        ctx.lineTo((this as any).currentMouse.x, (this as any).currentMouse.y);
        ctx.strokeStyle = (this as any).color;
        ctx.lineWidth = (this as any).strokeWidth;
        ctx.stroke();
      }
    },
    handleMouseDown(event: any) {
      const isTouch = event.type == "touchstart";

      (this as any).mouse.down = true;
      if (isTouch) event.preventDefault();
      (this as any).mouse.current = {
        x: isTouch ? event.touches[0].clientX : event.clientX,
        y: isTouch ? event.touches[0].clientY : event.clientY,
      };

      const canvas = this.$refs.canvas;
      var ctx = (canvas as any).getContext("2d");

      ctx.moveTo((this as any).currentMouse.x, (this as any).currentMouse.y);
    },
    handleMouseUp() {
      (this as any).mouse.down = false;
      const canvas: HTMLCanvasElement = (this as any).$refs.canvas;

      canvas.toBlob((blob) => {
        this.$emit("input", blob);
      });
    },
    handleMouseMove(event: any) {
      const isTouch = event.type == "touchmove";

      if (isTouch) event.preventDefault();
      (this as any).mouse.current = {
        x: isTouch ? event.touches[0].clientX : event.clientX,
        y: isTouch ? event.touches[0].clientY : event.clientY,
      };

      (this as any).draw(event);
    },
    clearCanvas(): void {
      (this as any).canvas.setTransform(1, 0, 0, 1, 0, 0);
      (this as any).canvas.clearRect(0, 0, 600, 600);
      (this as any).canvas.restore();
      (this as any).canvas.beginPath();
      (this as any).canvas.globalCompositeOperation = "source-over";
      (this as any).canvas.globalAlpha = 1;
      this.$emit("input", new Blob());
    },
    setupCanvas(): void {
      const canvas = (this as any).$refs.canvas;
      (this as any).canvas = canvas.getContext("2d");

      // (this as any).canvas.translate(0.5, 0.5);
      (this as any).canvas.imageSmoothingEnabled = false;
    },
  },
});
