
















import Vue from "vue";

export default Vue.extend({
  name: "errors",
  props: {
    value: Boolean,
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mValue: false,
    };
  },
  watch: {
    value(val: boolean): void {
      this.mValue = val;
    },
  },
});
