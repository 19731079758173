



















































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import DrawingBoard from "@/components/DrawingBoard.vue";
import DocumentViewer from "./DocumentViewer.vue";

import { getAPI } from "@/api/axios-base";
import City from "@/models/City";
import { mapActions, mapGetters, mapState } from "vuex";
import rules from "@/components/account/rules";
import {
  notificationError,
  notifyError,
  notifyInfo,
} from "@/components/Notification";
import Errors from "./Errors.vue";
import { AxiosError } from "axios";
import { apiBaseUrl } from "@/enviorment";
import TokenExpired from "./TokenExpired.vue";
import omitBy from "lodash/omitBy";

const MedicalInfo = () =>
  import(
    /* webpackChunkName: "medical-info-form" */ "@/views/PdfSign/wellcomePackage/MedicalInfo.vue"
  );
function VideoPhotoAutorization() {
  return import(
    /* webpackChunkName: "video-photo-autorization" */ "@/views/PdfSign/wellcomePackage/VideoPhotoAutorization.vue"
  );
}
function Allergies() {
  return import(
    /* webpackChunkName: "allergies" */ "@/views/PdfSign/wellcomePackage/Allergies.vue"
  );
}
function CurrentHealthProblems() {
  return import(
    /* webpackChunkName: "current-health-problems" */ "@/views/PdfSign/wellcomePackage/CurrentHealthProblems.vue"
  );
}
function EmergencyContact() {
  return import(
    /* webpackChunkName: "emergncy-contact" */ "@/views/PdfSign/wellcomePackage/EmergencyContact.vue"
  );
}
function PostSurgeryPatientPickInformation() {
  return import(
    /* webpackChunkName: "post-surgery-patient-pick-information" */ "@/views/PdfSign/wellcomePackage/PostSurgeryPatientPickInformation.vue"
  );
}
function PatientInformation() {
  return import(
    /* webpackChunkName: "post-patient-information" */ "@/views/PdfSign/wellcomePackage/PatientInformation.vue"
  );
}
function EndOfStep() {
  return import(/* webpackChunkName: "end-of-step" */ "./EndOfStep.vue");
}

export default Vue.extend({
  components: {
    MedicalInfo,
    VideoPhotoAutorization,
    DrawingBoard,
    Allergies,
    CurrentHealthProblems,
    EmergencyContact,
    PostSurgeryPatientPickInformation,
    PatientInformation,
    Errors,
    DocumentViewer,
    EndOfStep,
    TokenExpired,
  },
  name: "step2",
  data() {
    return {
      formValid: false,
      stepSended: false,
      showDocuments: false,
      videoAndPhonoDateMenu: false,
      wellcomeAutorizationDateMenu: false,
      wellcomeAutorizationDate: "", // Poner en el wellcome cuando esté
      stateCities: [],
      state: "",
      mailingCity: "",
      fullname: "",
      loadingCities: false,
      loading: false,
      rules,
      signature: new Blob(),
      showErrors: false,
      errorMessage: "",
      medicalInfo: {},
      videoAndPhono: {},
      allergies: {},
      emergencyContactData: {},
      currentHealthProblems: {},
      postSurgeryPatientInformation: {},
      patientInformation: {},
      tokenExpired: false,
    };
  },
  computed: {
    ...mapGetters(["getpatientN"]),
    ...mapState("crmMedicFormModule", ["states", "loadingStates"]),
    maxWidth(): number | string {
      const { xs, sm, md } = this.$vuetify.breakpoint;
      let result: number | string = "50%";

      if (xs) result = "100%";
      if (sm) result = "90%";
      if (sm) result = "90%";
      if (md) result = "80%";

      return result;
    },
    canSubmit(): boolean {
      const haveSignature = this.signature.size > 0;

      return haveSignature && this.formValid;
    },
    documents(): any[] {
      const token = this.$route.params.uuid;

      return [
        {
          title: "Wellcome package",
          url: `${apiBaseUrl}/patient/getWelcome_PackagePdf/${token}`,
        },
        {
          title: "Acknowledgement",
          url: `${apiBaseUrl}/patient/getAcknowledgementPdf/${token}`,
        },
        {
          title: "Medical information",
          url: `${apiBaseUrl}/patient/getPdfMedicalInformation/${token}`,
        },
        {
          title: "Video and photo autorization",
          url: `${apiBaseUrl}/patient/getPdfVideoPhoto/${token}`,
        },
        {
          title: this.getpatientN + " rights",
          url: `${apiBaseUrl}/patient/getPdfPatientRight/${token}`,
        },
      ];
    },
  },
  watch: {
    state: function (stateId: number): void {
      if (stateId == undefined || stateId == null) {
        this.stateCities = [];
        this.loadingCities = false;

        return;
      }

      this.loadingCities = true;
      this.actGetCityByState(stateId).then((cities: City[]) => {
        this.loadingCities = false;
        (this.stateCities as any) = cities;
      });
    },
  },
  mounted() {
    this.checkToken();
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListStates", "actGetCityByState"]),
    async sendData() {
      this.loading = true;
      const formData = new FormData();
      let body: any = {
        medicalInfo: {
          ...this.medicalInfo,
        },
        videoAndPhono: {
          ...this.videoAndPhono,
        },
        welcome: {
          ...this.allergies,
          ...this.emergencyContactData,
          ...this.currentHealthProblems,
          ...this.postSurgeryPatientInformation,
          ...this.patientInformation,
          fullname: this.fullname,
        },
        token: this.$route.params.uuid,
      };

      formData.append("firma", this.signature, `signature-${body.token}`);

      // Normalize data to ISO
      body.medicalInfo.date = new Date(body.medicalInfo.date).toISOString();
      body.welcome.birthDate = new Date(body.welcome.birthDate).toISOString();
      if (body.welcome.lastExamDate != "") {
        body.welcome.lastExamDate = new Date(
          body.welcome.lastExamDate
        ).toISOString();
      }

      if (body.welcome.postDate != "") {
        body.welcome.postDate = new Date(body.welcome.postDate).toISOString();
      }
      if (body.welcome.postProcedureDate != "") {
        body.welcome.postProcedureDate = new Date(
          body.welcome.postProcedureDate
        ).toISOString();
      }

      // Set +1 to phones numbers
      body.medicalInfo.phone = "+1" + body.medicalInfo.phone;
      body.welcome.doctorPhone = "+1" + body.welcome.doctorPhone;
      body.welcome.contactNumber = "+1" + body.welcome.contactNumber;
      if (this.emergencyContactData != {}) {
        body.welcome.emergencyContactNumber =
          "+1" + (this as any).emergencyContactData.emergencyContactNumber;
      }

      body.welcome.caregiverPhone = "+1" + body.welcome.caregiverPhone;
      if (this.postSurgeryPatientInformation != {}) {
        body.welcome.recoveryPhone =
          "+1" + (this as any).postSurgeryPatientInformation.recoveryPhone;
      }
      // body.welcome.emergencyContact = "+1" + body.welcome.emergencyContact;
      body.videoAndPhono.phoneParticipant =
        "+1" + body.videoAndPhono.phoneParticipant;
      body.videoAndPhono.phoneParent = "+1" + body.videoAndPhono.phoneParent;

      // Remove empty fields
      (body as any) = omitBy(body, (item) => item == "");

      try {
        await getAPI.post("/patient/signStep2", body);
        await getAPI({
          method: "post",
          url: `/patient/uploadPatienSign/${body.token}/STEP_2`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });

        notifyInfo("Your request has been sended for revision");
        this.stepSended = true;
        this.loading = false;
      } catch (error: any) {
        let mess = error.response.data.message;

        if (mess.includes("[")) {
          mess = mess.replace("[", "");
          mess = mess.replace("]", "");
        }
        notifyError(error.response.data, `An error occurred: ${mess}`);

        this.loading = false;
      }
    },
    checkToken() {
      getAPI("/patient/checkToken/" + this.$route.params.uuid)
        .then(() => {
          this.actListStates();
          setTimeout(() => {
            (this as any).$refs.form.validate();
          }, 4000);
        })
        .catch((error: AxiosError) => {
          if (error.response?.status == 404) {
            this.tokenExpired = true;
          }
        });
    },
  },
});
